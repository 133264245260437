<template>
  <div class="main-contents display terms">
    <div class="tit">약관 등록/수정<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')"/></div>
    <div class="sub-tit"><p>약관 등록/수정</p></div>
    <div class="banner_box">
      <div class="list">
        <div class="label">약관명</div>
          <SelectComp v-model="provisInfo.stipDivCd" codeKey="cd" nameKey="cdNm" :list="stipNmList" title="약관명" :rules="{'required':true}" :disabled="stipDivCd != ''"/>
      </div>
      <div class="list matter">
        <div class="label">약관내용</div>
          <textarea v-model="provisInfo.stipCont" style="transform: none;"></textarea>
      </div>
      <div class="list">
        <div class="label">미리보기</div>
        <div class="ex_area">
          <div class="ex_button" @click="provisInfoPreview()">미리보기 &#9654;</div>
        </div>
      </div>
      <div class="list">
        <div class="label">필수여부</div>
        <SelectComp v-model="provisInfo.mandYn" list="Y:필수,N:선택" title="필수여부"/>
      </div>
      <!-- <div class="list">
        <div class="label">사용여부</div>
        <SelectComp v-model="provisInfo.useYn" list="Y:예,N:아니오" title="사용여부"/>
      </div> -->
      <div class="list matter note">
        <div class="label">비고</div>
        <textarea v-model="provisInfo.note" style="transform: none;"/>
      </div>
    </div>
    <div class="select_btn">
      <div class="btn_01" @click="movePage('back')">취소</div>
      <div class="btn_02" v-if="stipDivCd == '' && stipNm == ''" @click="setProvisionInfo()">등록</div>
      <div class="btn_02" style="background-color: #FF681D;" v-else @click="setProvisionInfo()">수정</div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
      return{
        provisInfo : {
          stipVer: '',
          stipDivCd: '',
          stipCont: '',
          mandYn: '',
          // useYn: '',
          note:'',
        },

        stipNmList:[],


        stipDivCd : this.$route.params.stipDivCd || '',
        stipNm : this.$route.params.stipNm || '',
        stipVer : this.$route.params.stipVer || ''
      }
    },

    beforeMount(){
      if(this.stipDivCd != '') {
        this.getProvisionInfo(); 
        this.getStipNmList();
        }
      this.getStipNmList();
    },

    methods : {
      getProvisionInfo(){
        var param = {};
        param.stipDivCd = this.stipDivCd;

        this.$.httpPost('/api/main/adm/provis/getProvisionInfo', {stipDivCd : this.stipDivCd, stipVer : this.stipVer})
          .then(res=>{
            this.provisInfo = res.data.provisInfo || {};
          }).catch(this.$.httpErrorCommon);
      },

      setProvisionInfo(){
        this.$.popup('/adm/man/MAN932P01')
          .then(res=>{
            if(res){
              this.$.httpPost('/api/main/adm/provis/setProvisionInfo', this.provisInfo)
                .then(()=>{
                  this.movePage();
                }).catch(this.$.httpErrorCommon);
            }
          })
      },

      movePage(div){
        if(div == 'back') this.$router.go(-1);
        else this.$router.push('MAN932M01');
      },

      // 약관명 조회
      getStipNmList(){
        this.$.httpPost('/api/main/adm/provis/getStipNmList', {stipDivCd : this.stipDivCd})
              .then(res => {
                  this.stipNmList = res.data.stipNmList;
              }).catch(this.$.httpErrorCommon);
        },
      provisInfoPreview() {
        var param ={
          stipDivCd : this.provisInfo.stipDivCd,
          stipCont : this.provisInfo.stipCont,
        }
          this.$.popup('/adm/man/MAN932P02', param)
          .then(()=>{
          })
      },

      }
  }
</script>